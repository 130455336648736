/* eslint-disable */
import React from 'react'
import Helmet from 'react-helmet';

class SEO extends React.Component {
  unserialize = function(data) {
    var that = this,
      utf8Overhead = function (chr) {
        // http://phpjs.org/functions/unserialize:571#comment_95906
        var code = chr.charCodeAt(0);
        if (code < 0x0080) {
          return 0;
        }
        if (code < 0x0800) {
          return 1;
        }
        return 2;
      },
      error = function (type, msg, filename, line) {},
      read_until = function (data, offset, stopchr) {
        var i = 2, buf = [], chr = data.slice(offset, offset + 1);

        while (chr != stopchr) {
          if ((i + offset) > data.length) {
            error('Error', 'Invalid');
          }
          buf.push(chr);
          chr = data.slice(offset + (i - 1), offset + i);
          i += 1;
        }
        return [buf.length, buf.join('')];
      },
      read_chrs = function (data, offset, length) {
        var i, chr, buf;

        buf = [];
        for (i = 0; i < length; i++) {
          chr = data.slice(offset + (i - 1), offset + i);
          buf.push(chr);
          length -= utf8Overhead(chr);
        }
        return [buf.length, buf.join('')];
      },
      _unserialize = function (data, offset) {
        var dtype, dataoffset, keyandchrs, keys, contig,
          length, array, readdata, readData, ccount,
          stringlength, i, key, kprops, kchrs, vprops,
          vchrs, value, chrs = 0,
          typeconvert = function (x) {
            return x;
          };

        if (!offset) {
          offset = 0;
        }
        dtype = (data.slice(offset, offset + 1)).toLowerCase();

        dataoffset = offset + 2;

        switch (dtype) {
          case 'i':
            typeconvert = function (x) {
              return parseInt(x, 10);
            };
            readData = read_until(data, dataoffset, ';');
            chrs = readData[0];
            readdata = readData[1];
            dataoffset += chrs + 1;
            break;
          case 'b':
            typeconvert = function (x) {
              return parseInt(x, 10) !== 0;
            };
            readData = read_until(data, dataoffset, ';');
            chrs = readData[0];
            readdata = readData[1];
            dataoffset += chrs + 1;
            break;
          case 'd':
            typeconvert = function (x) {
              return parseFloat(x);
            };
            readData = read_until(data, dataoffset, ';');
            chrs = readData[0];
            readdata = readData[1];
            dataoffset += chrs + 1;
            break;
          case 'n':
            readdata = null;
            break;
          case 's':
            ccount = read_until(data, dataoffset, ':');
            chrs = ccount[0];
            stringlength = ccount[1];
            dataoffset += chrs + 2;

            readData = read_chrs(data, dataoffset + 1, parseInt(stringlength, 10));
            chrs = readData[0];
            readdata = readData[1];
            dataoffset += chrs + 2;
            if (chrs != parseInt(stringlength, 10) && chrs != readdata.length) {
              error('SyntaxError', 'String length mismatch');
            }
            break;
          case 'a':
            readdata = {};

            keyandchrs = read_until(data, dataoffset, ':');
            chrs = keyandchrs[0];
            keys = keyandchrs[1];
            dataoffset += chrs + 2;

            length = parseInt(keys, 10);
            contig = true;

            for (i = 0; i < length; i++) {
              kprops = _unserialize(data, dataoffset);
              kchrs = kprops[1];
              key = kprops[2];
              dataoffset += kchrs;

              vprops = _unserialize(data, dataoffset);
              vchrs = vprops[1];
              value = vprops[2];
              dataoffset += vchrs;

              if (key !== i)
                contig = false;

              readdata[key] = value;
            }

            if (contig) {
              array = new Array(length);
              for (i = 0; i < length; i++)
                array[i] = readdata[i];
              readdata = array;
            }

            dataoffset += 1;
            break;
          default:
            error('SyntaxError', 'Unknown / Unhandled data type(s): ' + dtype);
            break;
        }
        return [dtype, dataoffset - offset, typeconvert(readdata)];
      }
    ;

    return _unserialize((data + ''), 0)[2];
  }

  render() {
    var meta = this.unserialize(this.props.meta) || '';

    const title = this.props.title || meta.title || 'Scotties Facial';
    const keywords = meta.keywords || '';
    const description = meta.description || '';

    return (
      <Helmet
        title={title}
        htmlAttributes={{"lang": "en"}}
        meta={[
          { name: 'description', content: description },
          { name: 'keywords', content: keywords },
        ]}
      >
        <script type="application/javascript">{`
        var b = document.documentElement;
        b.setAttribute('data-useragent',  navigator.userAgent);
        b.setAttribute('data-platform', navigator.platform );
        b.className += ((!!('ontouchstart' in window) || !!('onmsgesturechange' in window))?' touch':'');
        `}</script>
        <script type="text/javascript">{`
          var _iub = _iub || [];
          _iub.csConfiguration = {"enableCcpa":true,"countryDetection":true,"consentOnContinuedBrowsing":false,"ccpaAcknowledgeOnDisplay":true,"whitelabel":false,"lang":"en","siteId":2102408,"cookiePolicyId":50685031, "banner":{ "acceptButtonDisplay":true,"customizeButtonDisplay":true,"acceptButtonColor":"#0073CE","acceptButtonCaptionColor":"white","customizeButtonColor":"#DADADA","customizeButtonCaptionColor":"#4D4D4D","rejectButtonColor":"#0073CE","rejectButtonCaptionColor":"white","position":"float-bottom-right","textColor":"black","backgroundColor":"white" }};        `}</script>
        <script type="text/javascript" src="//cdn.iubenda.com/cs/ccpa/stub.js"></script>
        <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charSet="UTF-8" async></script>
        {this.props.children}
      </Helmet>
    )
  }
}

export default SEO
