/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby";
import GeneralFooter from '../components/generalFooter';

import Rules from "./../layouts/images/ready-images/rules-v2.pdf";

import '../layouts/css/webflow.css'
import '../layouts/css/scotties.webflow.css'
import '../layouts/css/scotties.css'
import '../layouts/css/overrides.css'
import '../layouts/css/ie.css'

if (typeof window !== 'undefined') {
  // Make scroll behavior of internal links smooth
  // eslint-disable-next-line global-require

  require('smooth-scroll')('a.anchor', {
    speed: 1000
  });
}

const Layout = ({ children, className, data }) => {
  if (typeof window !== 'undefined') {
    if (window.location.search) {
      sessionStorage.setItem('incomingsource', window.location.search);
    }
  }

  return (
  <div className={className}>
    <div>
      {children}
    </div>
    <img style={{'position':'absolute', visibility:'hidden',color: 'transparent'}} className="fb-pixel" width="0" height="0" src="https://www.facebook.com/tr?id=199508674022010&ev=PageView&noscript=1" alt="Pixel" />
    <img style={{'position':'absolute', visibility:'hidden',color: 'transparent'}} width="0" height="0" src="https://www.facebook.com/tr?id=1723838924437800&ev=PageView&noscript=1" alt="Pixel" />
    <img style={{'position':'absolute', visibility:'hidden',color: 'transparent'}} width="0" height="0" src="https://ib.adnxs.com/pixie?pi=6b8a425d-b687-4cfa-85fa-38e63a549593&e=PageView&script=0" alt="Pixel" />
    <div id="modal" />
  </div>

  )
}

Layout.propTypes = {
  children: PropTypes.func,
}

export default Layout
